import React from 'react'
import "../Style.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationCrosshairs, faLocationDot } from '@fortawesome/free-solid-svg-icons'
import Images from '../../../Images'
import { Icons } from '../../../Icons/IconIndex' 
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'; 
import arrow from '../../../Images/arrow.png';

const Banner4 = () => {
  return ( 
    
    <div>
  <div className="container mt-5 mobile_card">
    <div className="row px-0 mx-0">
      <div className="col-sm-6 col-md-12 col-lg-3 col-xl-3 how-is-word mb-5">
        <div className="card howis-work-card px-4 pt-3 h-100">
          <div className="row px-0 mx-0">
            <div className="col-xl-3 col-md-12 col-lg-3 image-div">
            <img src={Images.search} className="card-image" alt="search" />
            </div>
            <div className="col-xl-9 col-md-12 col-lg-9">
            <h5 className="banner_font_size Carousel-name  my-3 mx-0">Search</h5>
            </div>
          </div>
          <div>
            <p className="price text-center">Use our advanced search mechanism to find the perfect venue for you.</p>
          </div>
        </div>
        <div className="arrow-container px-3 d-flex justify-content-center">
          <img className="products-image mx-2" src={arrow} alt="venue" />
        </div>
      </div>

      <div className="col-sm-6 col-md-12 col-lg-3 col-xl-3 how-is-word mb-5">
        <div className="card howis-work-card px-4 pt-3 h-100">
          <div className="row px-0 mx-0">
          <div className="col-xl-3 col-md-12 col-lg-3 image-div">
            <img src={Images.check} className="card-image" alt="check" />
            </div>
            <div className="col-xl-9 col-md-12 col-lg-9">
            <h5 className="banner_font_size Carousel-name my-2 mx-0">Check for Hall Availability</h5>
            </div>
          </div>
          <div>
            <p className="price text-center">Our specialized venue manager will talk to you and help checking on the hall availability.</p>
          </div>
        </div>
        <div className="arrow-container px-3 d-flex justify-content-center">
          <img className="products-image mx-2" src={arrow} alt="venue" />
        </div>
      </div>

      <div className="col-sm-6 col-md-12 col-lg-3 col-xl-3 how-is-word mb-5">
        <div className="card howis-work-card px-4 pt-3 h-100">
          <div className="row px-0 mx-0">
            <div className='col-xl-3 col-md-12 col-lg-3 image-div'>
            <img src={Images.book} className="card-image" alt="book" />
            </div>
            <div className='col-xl-9 col-md-12 col-lg-9'>
            <h5 className="banner_font_size Carousel-name  my-3 mx-0">Book the Hall</h5>
            </div>
          </div>
          <div>
            <p className="price text-center">Visit the hall directly if you want or book online with help of our venue manager.</p>
          </div>
        </div>
        <div className="arrow-container px-3 d-flex justify-content-center">
          <img className="products-image mx-2" src={arrow} alt="venue" />
        </div>
      </div>

      <div className="col-sm-6 col-md-12 col-lg-3 col-xl-3 how-is-word mb-5" style={{ margin: '0' }}>
        <div className="card howis-work-card px-4 pt-4 h-100">
          <div className="row">
            <div className='col-xl-3 col-md-12 col-lg-3 image-div'>
            <img src={Images.free} className="free-img card-image " alt="free" />
            </div>
            <div className='col-xl-9 col-md-12 col-lg-9'>
            <h5 className="banner_font_size Carousel-name my-3 mx-0">Celebrate Here!</h5>
            </div>
          </div>
          <div>
            <p className="price text-center">
              Kickstart the celebration here
            </p>
          </div>
        </div>
        <div className="arrow-container px-3 d-flex justify-content-center">
          <img className="products-image mx-2 d-none" src={arrow} alt="venue" />
        </div>
      </div>
    </div>
  </div>
</div>

  )
}

export default Banner4
