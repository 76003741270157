import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { apiURL } from '../../../Layout/Common/confiqurl';
import mandapam_new from '../../../Images/mandapam_new.png';

const Banner3 = ({ city, mandapamlist }) => {

  const navigate = useNavigate();
  const [cardData, setCardData] = useState([]);

  useEffect(() => {
    fetchMandapamDetails();
  }, []);

  const fetchMandapamDetails = () => {
    const payload = {
      city: city,
      // city: "Chennai",
      location: [],
      hall_capacity: "",
      hall_price: "0-100000",
      venue_type: [],
      parking_option: [],
      food_type: [],
      outside_dj: "",
      outside_decoration: ""
    };

    axios.post(`${apiURL}/Homepage_filter_data`, payload, {
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      }
    })
      .then((response) => {
        setCardData(response.data.data);
      })
      .catch(err => {
        toast.error(err.response?.data?.detail || "Error fetching data");
      });
  };

  const handlePress = (e, id) => {
    const value = { Location: e };
    axios.post(`${apiURL}/Homepage_details`, { Area: value.Location }, {
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json"
      }
    })
      .then((response) => {
        navigate("/FullDetails", { state: { id: id, value1: response.data, value2: value } });
      })
      .catch(err => {
        toast.error(err.response?.data?.detail || "Error processing request");
      });
  };

  const exploreText = city ? `Popular Venues in ${city}` : 'Popular Venues in Chennai';

  const responsive = {
    superLargeDesktop: { breakpoint: { max: 4000, min: 3000 }, items: 20,infinite: true,autoPlay: true, },
    desktop: { breakpoint: { max: 3000, min: 1024 }, items: 20 ,infinite: true,autoPlay: true,},
    tablet: { breakpoint: { max: 1024, min: 464 }, items: 2,infinite: true,autoPlay: true, },
    mobile: { breakpoint: { max: 464, min: 0 }, items: 1,infinite: true,autoPlay: true, }
  };


  return (
    <div className="Banner2" style={styles.banner}>
      <h3 style={styles.header}>{exploreText}</h3>

      {((mandapamlist && mandapamlist.length > 0) || (cardData && cardData.length > 0)) ? (
        <Carousel
          responsive={responsive}
          swipeable={true} 
          draggable={true} 
          autoPlaySpeed={3000}
          containerClass="carousel-container"
          itemClass="carousel-item-padding"
          style={styles.carousel}
        >
          {mandapamlist && mandapamlist.length > 0 ? (
            mandapamlist.map((item, index) => (
              <div className='slide-track' key={index} style={styles.slideTrack}>
                <div className="card banner-3 slide" style={styles.card}>
                  <img className="product-image" src={mandapam_new} alt="venue" style={styles.image} />
                  <h4 style={styles.carouselName}>{item.mandapam_name || "Unknown"}</h4>
                  <p className="price" style={styles.price}>{item.address}</p>
                  <button type="button" className="btn px-5 header-btn" onClick={() => handlePress(item.mandapam_name, item.id)} style={styles.button}>
                    Check Options
                  </button>
                </div>
              </div>
            ))
          ) : null}

          {cardData && cardData.length > 0 ? (
            cardData.map((item, index) => (
              <div className='slide-track' key={index} style={styles.slideTrack}>
                <div className="card banner-3 slide" style={styles.card}>
                  <img className="product-image" src={mandapam_new} alt="venue" style={styles.image} />
                  <h4 style={styles.carouselName}>{item.mandapam_name || "Unknown"}</h4>
                  <p className="price" style={styles.price}>{item.address}</p>
                  <button type="button" className="btn px-5 header-btn" onClick={() => handlePress(item.mandapam_name, item.id)} style={styles.button}>
                    Check Options
                  </button>
                </div>
              </div>
            ))
          ) : null}
        </Carousel>
      ) : (
        <div style={styles.noDataMessage}>
          {mandapamlist && mandapamlist.length === 0 && <p>No mandapams available</p>}
          {cardData && cardData.length === 0 && <p>No additional data available</p>}
        </div>
      )}
    </div>
  );
};

const styles = {
  banner: {
    padding: '20px 40px',
  },
  header: {
    marginTop: '0',
    marginBottom: '5rem',
    textAlign: 'center',
  },
  slideTrack: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',  
    marginLeft: 'auto', 
    marginRight: 'auto',  
  },
  card: {
    padding: '1rem',
    border: '1px solid #ddd',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
    margin: '0 10px', 
    width: '90%',  
    display: 'flex',  
    flexDirection: 'column', 
    justifyContent: 'space-between', 
  },
  image: {
    width: '100%',
    height: 'auto',
    borderRadius: '8px',
  },
  carouselName: {
    marginTop: '1rem',
    fontSize: '18px',
    fontWeight: 'bold',
  },
  price: {
    fontSize: '16px',
    margin: '10px 0',
    flexGrow: 1, 
  },
  button: {
    width: '100%',
    marginTop: '1rem',
    padding: '0.5rem',
    backgroundColor: 'rgb(222 174 36)',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  noDataMessage: {
    textAlign: 'center',
    fontSize: '18px',
    color: '#666',
  },
  price: {
    height: '50px',

  },
};


export default Banner3;
